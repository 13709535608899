// // home页面的所有数据
import instance from '@/utils/request.js'
import store from '@/store/index.js'
// 样板间的列表也数据
export const getTemplateData = ({
	current,
	size,
    salesRoomNo,
	designStyle,
	houseType,
	showHomePage,
    areaCode
}) => {
    const params = {
		current,
        size,
        salesRoomNo,
        designStyle,
        houseType,
        showHomePage,
        state: 1,
        clientId:'mj-portal',
		areaCode:areaCode || store.state.curCityInfo.value,
        orderBy:"sort asc"
	}
    if (store.state.curCityInfo.value == '00') {
        params.isHeadOffice = 1
    }
    if(params.areaCode=='00'){
        delete params.areaCode
    }

	return instance({
		url: '/portal/show/room/page',
		method: 'get',
		params
	})
}
// 查询 不限地区、终端的样板间
export const getTemplateDict = ({
	current,
	size,
    salesRoomNo,
	designStyle,
	houseType,
	showHomePage,
}) => {
    const params = {
		current,
        size,
        salesRoomNo,
        designStyle,
        houseType,
        showHomePage,
        state: 1,
        orderBy:"sort asc"
	}
	return instance({
		url: '/portal/show/room/page',
		method: 'get',
		params
	})
}
export const getTemplateDetail = (id) => {
    return instance({
      url: '/portal/show/room/' + id,
      method: 'get'
    })
  }
