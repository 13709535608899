
// 用户行为分析需要的值
import instance from '@/utils/request.js'
// 获取需要拿到的访客ID
export const getInterviewId = () => {
  return instance({
    url: '/portal/site/visit/log/interview-id'
  })
}
// 获取需要拿到的访次id
export const getVisitorId = () => {
  return instance({
    url: '/portal/site/visit/log/visitor-id'
  })
}
